ul {
    list-style-type: disc;
}

.indent-heading li {
    margin-top: 1%;
}

.indent-heading a {
    color: #fff;
    font-weight: normal;
}

.indent li {
    margin-top: 1%;
    list-style-type: circle;
}
a {
    color: #fff;
}

a:hover {
    color: #009688;
}
@media screen and (max-width: 400px) {
    .indent-headingla li {
        margin-left: -15%;
    }
}


body.light-theme {
    background-color: #F3F6F7;
}

body.dark-theme {
    background-color: #0e1316;
}

.light-theme .faqText {
    color: #000;
}

.dark-theme .faqText {
    color: #fff;
}

.light-theme .faqCard {
    background-color: #F3F6F7;
}

.dark-theme .faqCard {
    background-color: #0e1316;
}

.light-theme .faqLinks {
    color: #000;
}

.dark-theme .faqLinks {
    color: #fff;
}

.light-theme .faqLinks:hover {
    color: #009688;
}

.dark-theme .faqLinks:hover {
    color: #009688;
}
