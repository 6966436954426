.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
root {
  text-align: center;
  background-color: "#e6e1df"
}

body {
  text-align: center;
  background-color: "#e6e1df"
}


.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

body.light-theme {
  background-color: #F3F6F7;
} 

.light-theme .navbar {
  background-color: #F3F6F7;
}

.dark-theme .navbar {
  background-color: #0e1316;
}

.light-theme .navLinks {
  color: #000
}

.dark-theme .navLinks {
  color: #fff;
}

.light-theme .aboutUs {
  background-color: #F3F6F7;
}

.light-theme .aboutUsText {
  color: #000;
  font-weight: 500 !important;
}

.dark-theme .aboutUsText {
  color: #fff;
}

.light-theme .servicesSection {
  background-color: #2A3439;
}

.dark-theme .servicesSection {
  background-color: #0e1316;
}

.light-theme .accomplishments {
  background-color: #F3F6F7;
}

.dark-theme .accomplishments {
  background-color: #2A3439;
}

.light-theme .accomplishmentsCard {
  background-color: #F3F6F7;
}

.dark-theme .accomplishmentsCard {
  background-color: #2A3439;
}

.light-theme .accomplishmentsCardText {
  color: #000;
}

.dark-theme .accomplishmentsCardText {
  color: #fff;
}

.light-theme .testimonialSections {
  background-color: #F3F6F7;
}

.dark-theme .testimonialSections {
  background-color: #0e1316;
}

.light-theme .contactSection {
  background-color: #F3F6F7;
}

.dark-theme .contactSection {
  background-color: #0e1316;
}

.light-theme .contactText {
  color: #000;
}

.dark-theme .contactText {
  color: #fff;
}

.light-theme .languageSelector {
  color: #000;
}

.dark-theme .languageSelector {
  color: #fff;
}

.light-theme .button:hover {
  border: 3px solid #009688;
  background-color: transparent;
  color: #000;
}


.contactTextField {
  border: gray;
}

.ttk fieldset {
  border: 3px solid #009688 !important;
}

.ttk:hover {
  border: 3px solid #009688 !important;
}

.languageSelector.hiddenValue .MuiSelect-select {
  display: none;
}
