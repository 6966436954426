.foo a {
    display: block;
    font-size: 17px;
    color: #fff;
    margin-bottom: 0.5rem;
    text-decoration: none;
}

.foo a:hover {
    color: #009688;
    transition: 0.20;
}

    @media screen and (min-width: 260px) and (max-width: 350px) {}



    @media screen and (min-width:300px) and (max-width: 450px) {

        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 10px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 12px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            margin-left: 4px;
            width: 105px;
        }

        .footer-4 button {
            height: 30px;
            width: 45px
        }
    }

    @media screen and (min-width: 451px) and (max-width: 600px) {

        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 14px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 16px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            margin-left: 4px;
            width: 150px;
        }

        .footer-4 button {
            height: 30px;
            width: 45px
        }

    }

    @media screen and (min-width: 601px) and (max-width: 750px) {
        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 14px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 16px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            margin-left: 4px;
            width: 150px;
        }

        .footer-4 button {
            height: 30px;
            width: 45px
        }
    }

    @media screen and (min-width: 751px) and (max-width: 950px) {
        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 16px;
            margin-bottom: 22px;
        }

        .footer h2 {
            margin-bottom: 22px;
            font-size: 20px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            height: 33px;
            margin-left: 4px;
            width: 250px;
        }

        .footer-4 button {
            height: 33px;
            width: 60px
        }
    }

    @media screen and (min-width: 951px) and (max-width: 1280px) {

        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 22px;
            height: 300px;
        }

        .footer h3 {
            font-size: 17px;
            margin-top: 22px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 22px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 22px;
        }

        .footer input {
            height: 38px;
            margin-left: 4px;
            width: 250px;
        }

        .footer-4 button {
            height: 38px;
            width: 55px;
            font-size: 16px;
        }

        .footer-4 ::placeholder {
            font-size: 16px;
            align-content: center;
        }
    }

    @media screen and (min-width: 260px) and (max-width: 350px) {}



    @media screen and (min-width:300px) and (max-width: 450px) {

        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 10px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 12px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            margin-left: 4px;
            width: 105px;
        }

        .footer-4 button {
            height: 30px;
            width: 45px
        }
    }

    @media screen and (min-width: 451px) and (max-width: 600px) {

        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 14px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 16px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            margin-left: 4px;
            width: 150px;
        }

        .footer-4 button {
            height: 30px;
            width: 45px
        }

    }

    @media screen and (min-width: 601px) and (max-width: 750px) {
        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 14px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 16px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            margin-left: 4px;
            width: 150px;
        }

        .footer-4 button {
            height: 30px;
            width: 45px
        }
    }

    @media screen and (min-width: 751px) and (max-width: 950px) {
        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2px;
            height: 300px;
        }

        .footer h3 {
            font-size: 16px;
            margin-bottom: 22px;
        }

        .footer h2 {
            margin-bottom: 22px;
            font-size: 20px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 14px;
        }

        .footer input {
            height: 33px;
            margin-left: 4px;
            width: 250px;
        }

        .footer-4 button {
            height: 33px;
            width: 60px
        }
    }

    @media screen and (min-width: 951px) and (max-width: 1280px) {

        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 22px;
            height: 300px;
        }

        .footer h3 {
            font-size: 17px;
            margin-top: 22px;
        }

        .footer h2 {
            margin-bottom: 18px;
            font-size: 22px;
        }

        .footer-1,
        .footer-3 {
            margin-left: 22px;
        }

        .footer input {
            height: 38px;
            margin-left: 4px;
            width: 250px;
        }

        .footer-4 button {
            height: 38px;
            width: 55px;
            font-size: 16px;
        }

        .footer-4 ::placeholder {
            font-size: 16px;
            align-content: center;
        }
    }