body {
    background-color: #0e1316;
}

.top-section {
    background-image: url("../../../src/assets/img/landing.webp");
    height: 95vh;
    width: initial;
    background-repeat: no-repeat;
    background-size: cover;
}

.top-section h2 {
    background: transparent;
}

.top-section img {
    width: 90%;
}

.support-title {
    background-color: black;
    padding: 4.40vh;
    color: #fff;
    font-size: 1.5rem;

}

.about-section {
    width: auto;
}

.support-section li {
    padding: 0.5rem 1rem;
    border-bottom: 0.13vh solid #ddd;
    font-size: 0.938rem;
}

.support-section {
    display: flex;
    justify-content: center;
}

.about-section h6 {
    font-size: 1.5rem;
    color: #fff;
}

.support-title ul {
    padding-left: 0px;
}

.testimonial-section i {
    font-size: 200%;
    float: right;
    opacity: 0.1;
    transform: rotate(10deg) translate(-10px, -40px);
    margin-top: \40px;
}



* {
    box-sizing: border-box
}

body {
    margin: 0;
    padding: 0;
    font-family: Helvetica;
    font-weight: 100;
    color: #777;
}

@media (max-width: 500px) {
    .top-section {
        width: fit-content;
    }
}
